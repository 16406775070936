import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

const AnalyticsLink = (props) => {
  const { href, className, children, onClick, position, activity, type, step, isExternal, ...extraProps } = props;

  const handleClick = () => {
    onClick?.();
  };

  return (
    <Link href={href}>
      <a className={className} onClick={handleClick} { ...extraProps }>
        {children}
      </a>
    </Link>
  );
};

export default AnalyticsLink;

AnalyticsLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  position: PropTypes.string,
  activity: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.string,
  isExternal: PropTypes.bool,
};
