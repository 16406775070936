import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import styles from './articleBlock.module.scss';

export default function TestimonialCtaBlock({ data }) {
  const {
    city,
    cta_button: ctaButton,
    firstname_last_initial: name,
    quote,
    sub_header: subHeader,
    user_thumbnail: thumbnail,
  } = data;


  const { href, title } = ctaButton;

  return (
    <div className={cx(styles.testimonialCta, 'my-5')}>
      <div className={cx(styles.innerWrapper, 'd-flex flex-column align-items-center ')}>
        <div className={styles.thumbnail}>
          <img src={thumbnail?.url} alt="testimonial thumbnail" />
          <div className={styles.thumbnailBorder} />
        </div>

        <div className={cx(styles.personalDetails, 'd-flex flex-column align-items-center mt-3')}>
          <p>{name}</p>
          <span>{city}</span>
        </div>

        <div className={cx(styles.horizontalLine, 'mt-3')} />

        <p className={cx(styles.quote, 'mt-3 text-center')}>
          <div>“</div>{quote}<div>”</div>
        </p>

        <p className={cx(styles.subHeader, 'mt-2 text-center')}>{subHeader}</p>

        <LinkButton
          href={href}
          className="mt-1 mb-2 py-2 px-4"
          isExternal
        >
          {title}
        </LinkButton>
      </div>
    </div>
  );
}

TestimonialCtaBlock.propTypes = {
  data: PropTypes.shape({
    city: PropTypes.string,
    cta_button: PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
    }),
    firstname_last_initial: PropTypes.string,
    quote: PropTypes.string,
    sub_header: PropTypes.string,
    user_thumbnail: PropTypes.object,
  }),
};
